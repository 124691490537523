import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import VideoModal from "../../components/videoModal"

import withLocation from "../../components/withLocation"

class TheAbyssCollection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'tab1',
      videoModalOpen: false
    }

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  componentDidMount() {
    if (this.props.search.page === 'runway') {
      this.setState({ activeTab: 'tab3'})
    }
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  toggleVideoModal(e) {
    this.setState(prevState => ({
      videoModalOpen: !prevState.videoModalOpen 
    }))
  }

  render() {
    return (
      <Layout>
        <SEO title="The Abbys Collection" />

        <section className="hero-banner">
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.P9859_banner.childImageSharp.fluid} />
              <h1 className="hero-banner__title">
                The Abbys Collection
              </h1>
              <h2 className="hero-banner__subtitle">
              I found the right inspiration and mood in the black fashions of the Victorian era. I wanted to maintain the aristocratic appearance while the dark attributes of the era anchored the actual atmosphere and feeling of the entire collection.
              </h2>
            </div>
          </div>
        </section>

        <button onClick={this.toggleVideoModal} className="watch-video-btn button is-dark">Watch Video</button>
        <VideoModal videoId="YmrHQ7v6vh8" videoModalOpen={this.state.videoModalOpen} toggleVideoModal={this.toggleVideoModal} />

        <div className="tabs is-centered">
          <ul>
            <li className={ this.state.activeTab === "tab1" ? " is-active " : " "} onClick={() => this.toggleTab('tab1')}>
              <a>Campaign</a>
            </li>
            <li className={ this.state.activeTab === "tab2" ? " is-active " : " "} onClick={() => this.toggleTab('tab2')}>
              <a>Look book</a>
            </li>
            <li className={ this.state.activeTab === "tab3" ? " is-active " : " "} onClick={() => this.toggleTab('tab3')}>
              <a>Runway</a>
            </li>
          </ul>
        </div>
    
        <section className="collection-wrapper">
          <div id="tab-content">
            <div className={this.state.activeTab === "tab1" ? " is-active " : " "} data-content="1">
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P0010.childImageSharp.fluid} />
                  <Img fluid={this.props.data.P0057.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9819.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9881.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9889.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9940.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9973.childImageSharp.fluid} className="next-image" />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P0034.childImageSharp.fluid} />
                  <Img fluid={this.props.data.P0071.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9859.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9888_2.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9907_2.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9947.childImageSharp.fluid} className="next-image" />
                  <Img fluid={this.props.data.P9990.childImageSharp.fluid} className="next-image" />
                </div>
              </div>
            </div>
            <div className={this.state.activeTab === "tab2" ? " is-active " : " "} data-content="2">
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9362.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9395.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9378.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9383.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9360.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9335.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9355.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9303.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9417.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9437.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9431.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9442.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9513.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9478.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9522.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9500.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9570.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9650.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9594.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9671.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9709.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9689.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9718.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9697.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9722.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9758.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9740.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9773.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9871.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9850.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9795.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9883.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9837.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9896.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9988.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9966.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P9984.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9933.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P0013.childImageSharp.fluid} />
                </div>
              </div>
              
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P0027.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P9979.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P0039.childImageSharp.fluid} />
                </div>
              </div>
            </div>

            <div className={this.state.activeTab === "tab3" ? " is-active " : " "} data-content="3">
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_8515.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_9208.childImageSharp.fluid} />  
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.DSC_6064.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.DSC_6077.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_9128.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_8922.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_9145.childImageSharp.fluid} /> 
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_9272.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_9104.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.DSC_4268.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_9153.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.DSC_5937.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_8980.childImageSharp.fluid} /> 
                </div>
              </div>
              <div className="columns">
              <div className="column">
                  <Img fluid={this.props.data.MAC_9084.childImageSharp.fluid} />  
                </div>
                <div className="column">
                  <Img fluid={this.props.data.DSC_5747.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_9249.childImageSharp.fluid} /> 
                </div>
                <div className="column">
                  <Img fluid={this.props.data.DSC_5943.childImageSharp.fluid} /> 
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_8801.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.AA5A5769.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_8952.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_8623.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.DSC_5992.childImageSharp.fluid} /> 
                </div>
                <div className="column">
                  <Img fluid={this.props.data.MAC_9268.childImageSharp.fluid} />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_9189.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.AA5A5763.childImageSharp.fluid} />
                </div>
              </div>      
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.MAC_9372.childImageSharp.fluid} /> 
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

TheAbyssCollection.propTypes = {
  
}

export default withLocation(TheAbyssCollection);

export const fluidImageAbyssBanner = graphql`
fragment fluidImageAbyssBanner on File {
  childImageSharp {
    fluid(maxWidth: 2560) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidImageAbyss = graphql`
fragment fluidImageAbyss on File {
  childImageSharp {
    fluid(maxWidth: 1280) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    P9859_banner: file(relativePath: { eq: "collections/the-abyss/campaign/P9859_banner.jpg" }) {
      ...fluidImageAbyssBanner
    }
    P0010: file(relativePath: { eq: "collections/the-abyss/campaign/P0010.jpg" }) {
      ...fluidImageAbyss
    }
    P0034: file(relativePath: { eq: "collections/the-abyss/campaign/P0034.jpg" }) {
      ...fluidImageAbyss
    }
    P0057: file(relativePath: { eq: "collections/the-abyss/campaign/P0057.jpg" }) {
      ...fluidImageAbyss
    }
    P0071: file(relativePath: { eq: "collections/the-abyss/campaign/P0071.jpg" }) {
      ...fluidImageAbyss
    }
    P9819: file(relativePath: { eq: "collections/the-abyss/campaign/P9819.jpg" }) {
      ...fluidImageAbyss
    }
    P9859: file(relativePath: { eq: "collections/the-abyss/campaign/P9859.jpg" }) {
      ...fluidImageAbyss
    }
    P9881: file(relativePath: { eq: "collections/the-abyss/campaign/P9881.jpg" }) {
      ...fluidImageAbyss
    }
    P9888_2: file(relativePath: { eq: "collections/the-abyss/campaign/P9888-2.jpg" }) {
      ...fluidImageAbyss
    }
    P9889: file(relativePath: { eq: "collections/the-abyss/campaign/P9889.jpg" }) {
      ...fluidImageAbyss
    }
    P9907_2: file(relativePath: { eq: "collections/the-abyss/campaign/P9907-2.jpg" }) {
      ...fluidImageAbyss
    }
    P9940: file(relativePath: { eq: "collections/the-abyss/campaign/P9940.jpg" }) {
      ...fluidImageAbyss
    }
    P9947: file(relativePath: { eq: "collections/the-abyss/campaign/P9947.jpg" }) {
      ...fluidImageAbyss
    }
    P9973: file(relativePath: { eq: "collections/the-abyss/campaign/P9973.jpg" }) {
      ...fluidImageAbyss
    }
    P9990: file(relativePath: { eq: "collections/the-abyss/campaign/P9990.jpg" }) {
      ...fluidImageAbyss
    }
    P0013: file(relativePath: { eq: "collections/the-abyss/P0013.jpg" }) {
      ...fluidImageAbyss
    }
    P0027: file(relativePath: { eq: "collections/the-abyss/P0027.jpg" }) {
      ...fluidImageAbyss
    }
    P0039: file(relativePath: { eq: "collections/the-abyss/P0039.jpg" }) {
      ...fluidImageAbyss
    }
    P9303: file(relativePath: { eq: "collections/the-abyss/P9303.jpg" }) {
      ...fluidImageAbyss
    }
    P9335: file(relativePath: { eq: "collections/the-abyss/P9335.jpg" }) {
      ...fluidImageAbyss
    }
    P9355: file(relativePath: { eq: "collections/the-abyss/P9355.jpg" }) {
      ...fluidImageAbyss
    }
    P9360: file(relativePath: { eq: "collections/the-abyss/P9360.jpg" }) {
      ...fluidImageAbyss
    }
    P9362: file(relativePath: { eq: "collections/the-abyss/P9362.jpg" }) {
      ...fluidImageAbyss
    }
    P9378: file(relativePath: { eq: "collections/the-abyss/P9378.jpg" }) {
      ...fluidImageAbyss
    }
    P9383: file(relativePath: { eq: "collections/the-abyss/P9383.jpg" }) {
      ...fluidImageAbyss
    }
    P9395: file(relativePath: { eq: "collections/the-abyss/P9395.jpg" }) {
      ...fluidImageAbyss
    }
    P9417: file(relativePath: { eq: "collections/the-abyss/P9417.jpg" }) {
      ...fluidImageAbyss
    }
    P9431: file(relativePath: { eq: "collections/the-abyss/P9431.jpg" }) {
      ...fluidImageAbyss
    }
    P9437: file(relativePath: { eq: "collections/the-abyss/P9437.jpg" }) {
      ...fluidImageAbyss
    }
    P9442: file(relativePath: { eq: "collections/the-abyss/P9442.jpg" }) {
      ...fluidImageAbyss
    }
    P9478: file(relativePath: { eq: "collections/the-abyss/P9478.jpg" }) {
      ...fluidImageAbyss
    }
    P9500: file(relativePath: { eq: "collections/the-abyss/P9500.jpg" }) {
      ...fluidImageAbyss
    }
    P9513: file(relativePath: { eq: "collections/the-abyss/P9513.jpg" }) {
      ...fluidImageAbyss
    }
    P9522: file(relativePath: { eq: "collections/the-abyss/P9522.jpg" }) {
      ...fluidImageAbyss
    }
    P9570: file(relativePath: { eq: "collections/the-abyss/P9570.jpg" }) {
      ...fluidImageAbyss
    }
    P9594: file(relativePath: { eq: "collections/the-abyss/P9594.jpg" }) {
      ...fluidImageAbyss
    }
    P9650: file(relativePath: { eq: "collections/the-abyss/P9650.jpg" }) {
      ...fluidImageAbyss
    }
    P9671: file(relativePath: { eq: "collections/the-abyss/P9671.jpg" }) {
      ...fluidImageAbyss
    }
    P9689: file(relativePath: { eq: "collections/the-abyss/P9689.jpg" }) {
      ...fluidImageAbyss
    }
    P9697: file(relativePath: { eq: "collections/the-abyss/P9697.jpg" }) {
      ...fluidImageAbyss
    }
    P9709: file(relativePath: { eq: "collections/the-abyss/P9709.jpg" }) {
      ...fluidImageAbyss
    }
    P9718: file(relativePath: { eq: "collections/the-abyss/P9718.jpg" }) {
      ...fluidImageAbyss
    }
    P9722: file(relativePath: { eq: "collections/the-abyss/P9722.jpg" }) {
      ...fluidImageAbyss
    }
    P9740: file(relativePath: { eq: "collections/the-abyss/P9740.jpg" }) {
      ...fluidImageAbyss
    }
    P9758: file(relativePath: { eq: "collections/the-abyss/P9758.jpg" }) {
      ...fluidImageAbyss
    }
    P9773: file(relativePath: { eq: "collections/the-abyss/P9773.jpg" }) {
      ...fluidImageAbyss
    }
    P9795: file(relativePath: { eq: "collections/the-abyss/P9795.jpg" }) {
      ...fluidImageAbyss
    }
    P9837: file(relativePath: { eq: "collections/the-abyss/P9837.jpg" }) {
      ...fluidImageAbyss
    }
    P9850: file(relativePath: { eq: "collections/the-abyss/P9850.jpg" }) {
      ...fluidImageAbyss
    }
    P9871: file(relativePath: { eq: "collections/the-abyss/P9871.jpg" }) {
      ...fluidImageAbyss
    }
    P9883: file(relativePath: { eq: "collections/the-abyss/P9883.jpg" }) {
      ...fluidImageAbyss
    }
    P9896: file(relativePath: { eq: "collections/the-abyss/P9896.jpg" }) {
      ...fluidImageAbyss
    }
    P9933: file(relativePath: { eq: "collections/the-abyss/P9933.jpg" }) {
      ...fluidImageAbyss
    }
    P9966: file(relativePath: { eq: "collections/the-abyss/P9966.jpg" }) {
      ...fluidImageAbyss
    }
    P9979: file(relativePath: { eq: "collections/the-abyss/P9979.jpg" }) {
      ...fluidImageAbyss
    }
    P9984: file(relativePath: { eq: "collections/the-abyss/P9984.jpg" }) {
      ...fluidImageAbyss
    }
    P9988: file(relativePath: { eq: "collections/the-abyss/P9988.jpg" }) {
      ...fluidImageAbyss
    }
    AA5A5763: file(relativePath: { eq: "collections/the-abyss/runway/AA5A5763.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_5943: file(relativePath: { eq: "collections/the-abyss/runway/DSC_5943.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8623: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8623.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9084: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9084.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9208: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9208.jpg" }) {
      ...fluidImageAbyss
    }
    AA5A5769: file(relativePath: { eq: "collections/the-abyss/runway/AA5A5769.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_5992: file(relativePath: { eq: "collections/the-abyss/runway/DSC_5992.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8801: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8801.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9104: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9104.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9249: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9249.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_4268: file(relativePath: { eq: "collections/the-abyss/runway/DSC_4268.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_6064: file(relativePath: { eq: "collections/the-abyss/runway/DSC_6064.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9128: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9128.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9268: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9268.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_6077: file(relativePath: { eq: "collections/the-abyss/runway/DSC_6077.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8922: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8922.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9145: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9145.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9272: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9272.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_5747: file(relativePath: { eq: "collections/the-abyss/runway/DSC_5747.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8952: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8952.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9153: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9153.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9372: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9372.jpg" }) {
      ...fluidImageAbyss
    }
    DSC_5937: file(relativePath: { eq: "collections/the-abyss/runway/DSC_5937.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8515: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8515.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_8980: file(relativePath: { eq: "collections/the-abyss/runway/MAC_8980.jpg" }) {
      ...fluidImageAbyss
    }
    MAC_9189: file(relativePath: { eq: "collections/the-abyss/runway/MAC_9189.jpg" }) {
      ...fluidImageAbyss
    }
  }
`
